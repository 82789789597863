import { AccessApi } from '@/api/app/access/AccessApi';

export class AccessService {
    private api = new AccessApi();

   //获取客户列表区域搜索条件
    public getCustomer(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getCustomer().then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //获取客户列表区域搜索条件
    public getFloor(parentId:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getFloor(parentId).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}