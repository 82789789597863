
import AppBaseService from '@/api/attendance/base/AppBaseService';

export class AccessApi extends AppBaseService {
    constructor() {
        super("access");
    }
    //获取客户列表区域搜索条件
    public getCustomer(): Promise<any> {
        return this.request('customer', this.rest.METHOD_GET)
    }
    // 
    public getFloor(parentId: any): Promise<any> {
        return this.request('floor?parentId'+parentId,  this.rest.METHOD_GET)
    }
}