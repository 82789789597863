
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import { menuController } from "@ionic/vue";
import ApplyStatsMenu from "@/views/attendance/statistics/applyStats/ApplyStatsMenu.vue";
import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import { CancleLeaveService } from "@/services/attendance/cancelleave/CancelLeaveService";
import { onDutyService } from "@/services/attendance/onDuty/onDutyService";
import { StationService } from "@/services/attendance/station/StationService";
import StatsCard from "@/components/ListCard/StatsCard.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
export default defineComponent({
  name: "ApplyStatsPage",
  components: {
    Header,
    ApplyStatsMenu,
    StatsCard,
    SearchBar,
    CardLoading,
  },
  setup() {
    const userId = SystemUtils.loginUser ? SystemUtils.loginUser.id : "";
    const query = useRoute().query;
    const router = useRouter();
    const btnSizeRight = reactive(["38px"]);
    const btnIconRight = reactive(["iconshaixuan"]);
    const ApplyStatsList = reactive({ data: [] });
    const VacationLeaveApi = new VacationLeaveService();
    const CancleLeaveApi = new CancleLeaveService();
    const onDutyApi = new onDutyService();
    const StationApi = new StationService();
    const openFilter = () => {
      menuController.open("ApplyStatsMenu");
    };

    const searchParams = reactive({
      page: 1,
      size: 10,
      approveStatus: "",
      startTime: "",
      endTime: "",
      orgId: "",
    });
    const keyword = ref("");

    let showNoDate = ref(false);
    onMounted(() => {
      resetPage();
    });
    const resetPage = () => {
      searchParams.page = 1;
      getListRequest((res: any) => {
        ApplyStatsList.data = res;
        showNoDate.value = res == null || res.length <= 0;
      });
    };
    const nextPage = (event: any) => {
      searchParams.page++;
      getListRequest((result: any) => {
        if (result) {
          ApplyStatsList.data = ApplyStatsList.data.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    };
    const doRefresh = (event: any) => {
      searchParams.page = 1;
      keyword.value = "";
      searchParams.approveStatus = "";
      searchParams.startTime = "";
      searchParams.endTime = "";
      searchParams.orgId = "";
      getListRequest((res: any) => {
        ApplyStatsList.data = res;
        showNoDate.value = res == null || res.length <= 0;
      });
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    };
    const getListRequest = (callback: any) => {
      let params = {
        page: searchParams.page,
        size: searchParams.size,
        keyword: keyword.value,
        approveStatus: searchParams.approveStatus,
        startTime: searchParams.startTime,
        endTime: searchParams.endTime,
        orgId: searchParams.orgId,
      };
      if (query.title == "请假记录") {
        VacationLeaveApi.getList(params)
          .then((res: any) => {
            callback(res);
          })
          .catch(() => {});
      } else if (query.title == "销假记录") {
        CancleLeaveApi.getList(params).then((res: any) => {
          callback(res);
        });
      } else if (query.title == "出差记录") {
        let params = {
          page: searchParams.page,
          size: searchParams.size,
          keyword: keyword.value,
          approveStatus: searchParams.approveStatus,
          startTime: searchParams.startTime,
          endTime: searchParams.endTime,
          orgId: searchParams.orgId,
          type: 0,
        };
        onDutyApi.getList(params).then((res: any) => {
          callback(res);
        });
      } else if (query.title == "外出记录") {
        let params = {
          page: searchParams.page,
          size: searchParams.size,
          keyword: keyword.value,
          approveStatus: searchParams.approveStatus,
          startTime: searchParams.startTime,
          endTime: searchParams.endTime,
          orgId: searchParams.orgId,
          type: 1,
        };
        onDutyApi.getList(params).then((res: any) => {
          callback(res);
        });
      } else if (query.title == "驻场记录") {
        StationApi.getUserList(params).then((res: any) => {
          callback(res);
        });
      }
    };
    const filterHandler = (data: any) => {
      menuController.close();
      console.log(data);
      keyword.value = "";
      searchParams.approveStatus = data.status;
      searchParams.orgId = data.floorId;
      searchParams.startTime = data.startDate;
      searchParams.endTime = data.endDate;
      resetPage();
    };
    const todoInfo = (item: any) => {
      const path = ref("");
      let handleType = 2;
      if (query.title == "请假记录") {
        path.value = "/LeaveInfo";
      } else if (query.title == "销假记录") {
        path.value = "/CancelLeaveInfo";
      } else if (query.title == "出差记录") {
        path.value = "/TravelInfo";
      } else if (query.title == "外出记录") {
        path.value = "/OutSignInList";
      } else if (query.title == "驻场记录") {
        path.value = "/StationInfo";
      }
      if (query.title === "外出记录") {
        router.push({
          path: path.value,
          query: {
            id: item.id,
            startTime: item.startTime,
            endTime: item.endTime,
            userId: userId,
            type:0
          },
        });
      } else {
        router.push({
          path: path.value,
          query: {
            id: item.id,
            procId: item.procId,
            taskId: item.taskId,
            handleType: handleType,
            procStatus: item.workflowStatus,
            type: 0,
          },
        });
      }
    };
    const searchHandler = (data: any) => {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      keyword.value = data.searchKey;
      resetPage();
    };
    return {
      query,
      btnSizeRight,
      btnIconRight,
      ApplyStatsList,
      openFilter,
      filterHandler,
      nextPage,
      doRefresh,
      showNoDate,
      todoInfo,
      searchHandler,
      keyword,
    };
  },
});
