
import { menuController } from "@ionic/vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import moment from "moment";
import { AccessService } from "@/services/app/access/AccessService";
export default defineComponent({
  name: "ApplyStatsMenu",
  emits: ["on-close"],
  setup(props, context) {
    const api = new AccessService();
    const categoryArr = reactive({ data: [{id:""}] });
    const categoryArrF = reactive({ data: [{id:""}] });
    const apply_status = reactive([
      { name: "全部", value: "" },
      { name: "未审批", value: 0 },
      { name: "审批通过", value: 1 },
      { name: "审批拒绝", value: 2 },
      { name: "审批中", value: 3 },
    ]);

    const filter_CustomerId = ref("");
    const filter_FloorId = ref("");
    const filter_status = ref("");
    const filter_startDate = ref("");
    const filter_endDate = ref("");

    onMounted(() => {
      initData();
    });
    const initData = () => {
      api.getCustomer().then((res: any) => {
        categoryArr.data = res;
        filter_CustomerId.value = res[0].id;
      });
      api.getFloor(filter_CustomerId.value).then((res: any) => {
        categoryArrF.data = res;
        filter_FloorId.value = res[0].id;
      });
    };

    const onClose = () => {
      menuController.close();
    };

    const selectCategory = (val: any) => {
      filter_CustomerId.value = val;
    };
    const selectCategoryF = (val: any) => {
      filter_FloorId.value = val;
    };

    const selectStatus = (val: any) => {
      filter_status.value = val.value;
    };

    const resetTime = () => {
      filter_startDate.value = "";
      filter_endDate.value = "";
    };

    const reset = () => {
      filter_CustomerId.value = categoryArr.data[0].id;
      filter_FloorId.value = categoryArrF.data[0].id;
      filter_startDate.value = "";
      filter_endDate.value = "";
      filter_status.value = "";
      const params = {
        customerId: filter_CustomerId.value,
        floorId: filter_FloorId.value,
        startDate: filter_startDate.value,
        endDate: filter_endDate.value,
        status: filter_status.value,
      };
      context.emit("on-close", params);
    };

    const confirm = () => {
      if (filter_startDate.value != "") {
        filter_startDate.value = moment(filter_startDate.value).format(
          "YYYY-MM-DD"
        );
      }
      if (filter_endDate.value != "") {
        filter_endDate.value = moment(filter_endDate.value).format(
          "YYYY-MM-DD"
        );
      }
      const params = {
        customerId: filter_CustomerId.value,
        floorId: filter_FloorId.value,
        startDate: filter_startDate.value,
        endDate: filter_endDate.value,
        status: filter_status.value,
      };
      context.emit("on-close", params);
    };

    return {
      apply_status,
      categoryArr,
      categoryArrF,
      filter_CustomerId,
      filter_FloorId,
      filter_status,
      filter_startDate,
      filter_endDate,
      onClose,
      selectStatus,
      resetTime,
      selectCategoryF,
      selectCategory,
      reset,
      confirm,
    };
  },
});
